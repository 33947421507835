.menu-icon {
  width: 40px;
  height: 30px;
  position: relative;
  margin: auto;
  transform: rotate(0deg);
  transition: 0.9s ease-in-out;
  cursor: pointer;

  span {
    display: block;
    position: absolute;
    background-color: #000000;
    height: 5px;
    width: 100%;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
  }

  span:nth-child(1) {
    top: 0px;
  }

  span:nth-child(2),
  .menu-icon span:nth-child(3) {
    top: 15px;
  }

  span:nth-child(4) {
    top: 30px;
  }
}

.menu-icon-open {
  span {
    background-color: #f05d5e;
  }

  span:nth-child(1) {
    top: 15px;
    width: 0%;
    left: 50%;
  }

  span:nth-child(4) {
    top: 15px;
    width: 0%;
    left: 50%;
  }

  span:nth-child(2) {
    transform: rotate(45deg) translate(-15%, -100%);
  }

  span:nth-child(3) {
    transform: rotate(-45deg) translate(-15%, 100%);
  }
}
