.ui-position {
  &--fixed {
    position: fixed;
  }

  &--sticky {
    position: sticky;
  }

  &--absolute {
    position: absolute;
  }

  &--relative {
    position: relative;
  }
}

@for $i from 0 through 10 {
  $pos: $i * 10px;

  .ui-position {
    &--left-#{$i} {
      left: $pos;
    }

    &--right-#{$i} {
      right: $pos;
    }

    &--top-#{$i} {
      top: $pos;
    }

    &--bottom-#{$i} {
      bottom: $pos;
    }
  }
}

.ui-axis--normalize {
  transform: translate(-50%, -50%);
}

.ui-axis--left {
  transform: translateX(-50%);
}
