.ui-display {
  &--block {
    display: block;
  }

  &--inline-block {
    display: inline-block;
  }

  &--inline {
    display: inline;
  }

  &--none {
    display: none;
  }
}

.ui-grid {
  display: grid;
}

.ui-flex {
  display: flex;

  &--vertical {
    flex-direction: column;
  }

  &--grow {
    flex-grow: 1;

    &-2 {
      flex-grow: 2;
    }

    &-6 {
      flex-grow: 6;
    }
  }

  &--wrap {
    flex-wrap: wrap-reverse;
  }
}

.ui-vertical-align {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ui-space-evenly {
  justify-content: space-evenly;
}

.ui-space-between {
  justify-content: space-between;
}

.ui-space-around {
  justify-content: space-between;
}

.ui-center {
  justify-content: center;
}

.ui-min-vh {
  height: 100vh;
}

.ui-min-vw {
  width: 100vw;
}

.ui-min-vw--50 {
  width: 50vw;
}

.ui-min-vw--30 {
  width: 30vw;
}

.ui-min-w--100 {
  width: 100%;
}

.ui-min-h--100 {
  height: 100%;
}

.ui-overflow--hidden {
  overflow: hidden;
}

.ui-body {
  max-width: 1500px;
  text-align: center;
}

@media (max-width: 1800px) {
  .ui-body {
    width: 80vw;
  }
}

.ui-width-auto {
  width: auto;
}
