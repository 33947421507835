.social-logo {
  height: 40px;
  width: 40px;
}

.fade-in {
  animation: fade 2s;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
