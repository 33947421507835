@for $i from 0 through 10 {
  /*
    Mutliplier
  */
  $size: $i * 10px;

  /*
    Padding
  */
  .ui-p--#{$i} {
    padding: $size;
  }

  .ui-pt--#{$i} {
    padding-top: $size;
  }

  .ui-pb--#{$i} {
    padding-bottom: $size;
  }

  .ui-pl--#{$i} {
    padding-left: $size;
  }

  .ui-pr--#{$i} {
    padding-right: $size;
  }

  /*
    Margin
  */
  .ui-m--#{$i} {
    margin: $size;
  }

  .ui-mt--#{$i} {
    margin-top: $size;
  }

  .ui-mb--#{$i} {
    margin-bottom: $size;
  }

  .ui-ml--#{$i} {
    margin-left: $size;
  }

  .ui-mr--#{$i} {
    margin-right: $size;
  }
}

/*
  Auto Margin
*/
.ui-ma {
  margin: auto;
}

.ui-mra {
  margin-right: auto;
}

.ui-mla {
  margin-left: auto;
}

.ui-border {
  border-width: 1px;

  &--none {
    border: none;
  }

  &--solid {
    border-style: solid;
  }

  &--dashed {
    border-style: dashed;
  }

  &--dotted {
    border-style: dotted;
  }

  &--2 {
    border-width: 2px;
  }
}

@media (max-width: 1366px) {
  @for $i from 0 through 10 {
    /*
      Mutliplier
    */
    $size: $i * 8px;

    /*
      Padding
    */
    .ui-p--#{$i} {
      padding: $size;
    }

    .ui-pt--#{$i} {
      padding-top: $size;
    }

    .ui-pb--#{$i} {
      padding-bottom: $size;
    }

    .ui-pl--#{$i} {
      padding-left: $size;
    }

    .ui-pr--#{$i} {
      padding-right: $size;
    }

    /*
      Margin
    */
    .ui-m--#{$i} {
      margin: $size;
    }

    .ui-mt--#{$i} {
      margin-top: $size;
    }

    .ui-mb--#{$i} {
      margin-bottom: $size;
    }

    .ui-ml--#{$i} {
      margin-left: $size;
    }

    .ui-mr--#{$i} {
      margin-right: $size;
    }
  }
}

@media (max-width: 1024px) {
  @for $i from 0 through 10 {
    /*
      Mutliplier
    */
    $size: $i * 5px;

    /*
      Padding
    */
    .ui-p--#{$i} {
      padding: $size;
    }

    .ui-pt--#{$i} {
      padding-top: $size;
    }

    .ui-pb--#{$i} {
      padding-bottom: $size;
    }

    .ui-pl--#{$i} {
      padding-left: $size;
    }

    .ui-pr--#{$i} {
      padding-right: $size;
    }

    /*
      Margin
    */
    .ui-m--#{$i} {
      margin: $size;
    }

    .ui-mt--#{$i} {
      margin-top: $size;
    }

    .ui-mb--#{$i} {
      margin-bottom: $size;
    }

    .ui-ml--#{$i} {
      margin-left: $size;
    }

    .ui-mr--#{$i} {
      margin-right: $size;
    }
  }
}

@media (max-width: 760px) {
  @for $i from 0 through 10 {
    /*
      Mutliplier
    */
    $size: $i * 3px;

    /*
      Padding
    */
    .ui-p--#{$i} {
      padding: $size;
    }

    .ui-pt--#{$i} {
      padding-top: $size;
    }

    .ui-pb--#{$i} {
      padding-bottom: $size;
    }

    .ui-pl--#{$i} {
      padding-left: $size;
    }

    .ui-pr--#{$i} {
      padding-right: $size;
    }

    /*
      Margin
    */
    .ui-m--#{$i} {
      margin: $size;
    }

    .ui-mt--#{$i} {
      margin-top: $size;
    }

    .ui-mb--#{$i} {
      margin-bottom: $size;
    }

    .ui-ml--#{$i} {
      margin-left: $size;
    }

    .ui-mr--#{$i} {
      margin-right: $size;
    }
  }
}
