.ui-border-radius {
  border-radius: 3px;

  @for $i from 0 through 45 {
    &--#{$i} {
      border-radius: $i * 1px;
    }
  }
}

.ui-circle {
  border-radius: 50%;
}

.ui-shadow {
  box-shadow: 0 10px 10px 0 rgba(48, 48, 48, 0.07);
}

.ui-border-box {
  box-sizing: border-box;
}

.ui-keep-y {
  transform: translateY(0%);
}

.ui-bottom-border {
  border-bottom: 3px solid;
}

.ui-only-mobile {
  display: none;
}

@media (max-width: 1366px) {
  .ui-no-tablet {
    display: none;
  }

  .ui-only-mobile {
    display: block;
  }
}

@media (max-width: 760px) {
  .ui-no-mobile {
    display: none;
  }
}

@media (max-height: 500px) {
  .ui-landscape-no-mobile {
    display: none;
  }
}
