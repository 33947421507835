.ui-animate {
  &--delay {
    &-05 {
      animation-delay: 500ms;
    }

    &-10 {
      animation-delay: 1000ms;
    }

    &-15 {
      animation-delay: 1500ms;
    }

    &-20 {
      animation-delay: 2000ms;
    }

    &-25 {
      animation-delay: 2500ms;
    }

    &-30 {
      animation-delay: 3000ms;
    }

    &-5 {
      animation-delay: 3500ms;
    }
  }

  &--fast {
    animation-duration: 1000ms;
  }

  &--medium {
    animation-duration: 2000ms;
  }

  &--slow {
    animation-duration: 3000ms;
  }

  &--focus-in {
    animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  }

  &--rotate-clock {
    animation-name: rotate-clock;
  }

  &--rotate-counter-clock {
    animation-name: rotate-counter-clock;
  }

  &--focus-in {
    animation: focus-in-contract-bck 1s cubic-bezier(0.25, 0.46, 0.45, 0.94)
      both;
  }

  &--focus-in-expand {
    animation: focus-in-expand 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
}

@keyframes focus-in-contract-bck {
  0% {
    letter-spacing: 1em;
    transform: translateZ(300px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    transform: translateZ(12px);
    filter: blur(0);
    opacity: 1;
  }
}

@keyframes focus-in-expand {
  0% {
    letter-spacing: -0.5em;
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}

@keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}

@keyframes rotate-clock {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes rotate-counter-clock {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-360deg);
  }
}

@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
