$white: /*   */ #ffffff;
$black: /*   */ #000000;
$red: /*     */ #f05d5e;
$charcoal: /**/ #230f0f;
$olive: /*   */ #3f3a3a;
$blue: /*    */ #004c7b;
$pink: /*    */ #e2b7b7;
$brown: /*   */ #6b3030;

.ui-bg {
  &--white {
    background-color: $white;
  }

  &--black {
    background-color: $black;
  }

  &--red {
    background-color: $red;
  }

  &--charcoal {
    background-color: $charcoal;
  }

  &--olive {
    background-color: $olive;
  }

  &--blue {
    background-color: $blue;
  }

  &--pink {
    background-color: $pink;
  }

  &--brown {
    background-color: $brown;
  }
}

.ui-color {
  &--white {
    color: $white;
  }

  &--black {
    color: $black;
  }

  &--red {
    color: $red;
  }

  &--charcoal {
    color: $charcoal;
  }

  &--olive {
    color: $olive;
  }

  &--blue {
    color: $blue;
  }

  &--pink {
    color: $pink;
  }

  &--brown {
    color: $brown;
  }
}

.ui-border-color {
  &--white {
    border-color: $white;
  }

  &--black {
    border-color: $black;
  }

  &--red {
    border-color: $red;
  }

  &--charcoal {
    border-color: $charcoal;
  }

  &--olive {
    border-color: $olive;
  }

  &--blue {
    border-color: $blue;
  }

  &--pink {
    border-color: $pink;
  }

  &--brown {
    border-color: $brown;
  }
}

.ui-red--link-container {
  position: relative;
}

.ui-red--link:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  bottom: 0;
  left: 0;
  background: $red;
  visibility: hidden;
  border-radius: 5px;
  transform: scaleX(0);
  transition: 0.25s linear;
}

.ui-red--link:hover:before,
.ui-red--link:focus:before {
  visibility: visible;
  transform: scaleX(1);
}

.ui-black--link-container {
  position: relative;
}

.ui-black--link:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  bottom: 0;
  left: 0;
  background: $black;
  visibility: hidden;
  border-radius: 5px;
  transform: scaleX(0);
  transition: 0.25s linear;
}

.ui-black--link:hover:before,
.ui-black--link:focus:before {
  visibility: visible;
  transform: scaleX(1);
}
